<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>

      <v-toolbar-title>Уведомления</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        @click="deleteAllNotify"
        v-if="notifications && notifications.length > 0"
        text
        tile
        :loading="dellLoading"
        >Удалить все</v-btn
      >
    </v-toolbar>
    <v-container v-if="!loadPage">
      <v-row align="center">
        <no-content
          v-if="!notifications || notifications.length == 0"
          title="У вас нет уведомлений"
        ></no-content>
        <notify-card
          v-for="(notify, index) in notifications"
          :key="notify.id"
          :notify="notify"
          :index="index"
        ></notify-card>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NotifyCard from "../account/NotifyCard.vue";

export default {
  components: {
    NotifyCard,
  },
  data: () => ({
    dellLoading: false,
  }),
  methods: {
    deleteAllNotify() {
      let app = this;
      app.dellLoading = true;
      this.$store
        .dispatch("dellAllNotify")
        .then(() => {
          app.dellLoading = false;
        })
        .catch(() => {
          app.dellLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      notifications: "notifications",
    }),
  },
  created() {
    this.$store.dispatch("getNotifications");
  },
};
</script>
