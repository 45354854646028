<template>
  <v-col class="text-left" cols="12">
    <v-card class="notify-card" :class="classCard">
      <div class="notify-wrapper">
        <router-link
          v-if="notify.url_path"
          :to="{ path: notify.url_path }"
          class="notify-link"
        >
        </router-link>
        <div class="left">
          <v-icon :color="colorIcon" x-large class="mt-4 ml-4"
            >$vuetify.icons.{{ icon }}</v-icon
          >
        </div>
        <div class="right">
          <v-card-title>{{ notify.title }}</v-card-title>
          <v-card-text>{{ notify.body }}</v-card-text>
        </div>
      </div>
      <v-card-actions>
        <div class="one-comment-date ml-2">
          {{ notify.dateFormat || "только что" }}
        </div>
        <v-spacer></v-spacer>
        <!-- <v-btn v-if="notify.url_path" :to="{ path: notify.url_path }" icon>
          <v-icon>$vuetify.icons.eye</v-icon>
        </v-btn> -->

        <v-btn :loading="loading" @click.stop="deleteNotify" icon>
          <v-icon>$vuetify.icons.delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["notify", "index"],
  data() {
    return {};
  },
  methods: {
    deleteNotify() {
      // console.log("delete", this.index);
      // this.$store.commit("DELETE_NOTIFY", this.index);
      let app = this;
      this.$store.dispatch("dellNotify", {
        notify_id: app.notify.id,
        index: app.index,
      });
    },
  },
  computed: {
    ...mapGetters({
      loading: "notify_dell_loading",
    }),
    type() {
      let type = "default";
      if (this.notify && this.notify.typeNotify) {
        type = this.notify.typeNotify;
      }
      return type;
    },
    classCard() {
      let classBody = this.colorBody;
      if (this.notify && this.notify.read_at) {
        classBody = classBody + " read_at";
      }
      return classBody;
    },
    colorBody() {
      let color = "";
      if (this.notify && this.type) {
        switch (this.type) {
          case "payout":
            color = "payout";
            break;
          default:
            color = "";
            break;
        }
      }
      return color;
    },
    colorIcon() {
      let color = "";
      if (this.notify && this.type) {
        switch (this.type) {
          case "default":
            color = "blue";
            break;
          case "info":
            color = "blue";
            break;
          case "like":
            color = "green darken-3";
            break;
          case "library":
            color = "green darken-3";
            break;
          case "sale":
            color = "#6EBD48";
            break;
          case "comment":
            color = "red";
            break;
          case "book":
            color = "red";
            break;
          case "payout":
            color = "blue";
            break;

          default:
            color = "blue";
            break;
        }
      }
      return color;
    },
    icon() {
      // console.log(this.$vuetify.icons.information);
      // return this.$vuetify.icons.values.information;
      let icon = "information";

      if (this.notify && this.type) {
        switch (this.type) {
          case "default":
            icon = "information";
            break;
          case "info":
            icon = "info";
            break;
          case "like":
            icon = "star";
            break;
          case "library":
            icon = "bookshelf";
            break;
          case "comment":
            icon = "comment_text_outline";
            break;
          case "sale":
            icon = "purse";
            break;
          case "book":
            icon = "book";
            break;
          case "payout":
            icon = "wallet_plus";
            break;

          default:
            icon = "information";
            break;
        }
      }
      return icon;
    },
  },
  mounted() {
    // console.log(this.notify.url_path);
  },
};
</script>
